var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tw-p-4 lg:tw-p-5 tw-bg-ci-gray-50"},[_c('div',{staticClass:"tw-grid lg:tw-grid-cols-2 lg:tw-gap-0.5"},[_c('div',{staticClass:"lg:tw-col-start-1 tw-flex tw-flex-row"},[_c('div',{staticClass:"tw-w-1/2 lg:tw-w-1/3"},[_vm._v("Betrag in EUR:")]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(_vm.transaction.amount)))])]),_c('div',{staticClass:"lg:tw-col-start-2 tw-flex tw-flex-col lg:tw-flex-row"},[_c('div',{staticClass:"lg:tw-w-1/3"},[_vm._v("Verwendungszweck:")]),_c('div',[_vm._v(" "+_vm._s(_vm.transaction.details ? _vm.transaction.details.purpose : '(nicht sichtbar)')+" ")])]),_c('div',{staticClass:"lg:tw-col-start-1 tw-flex tw-flex-row"},[_c('div',{staticClass:"tw-w-1/2 lg:tw-w-1/3"},[_vm._v("Buchungstag:")]),_c('div',[_vm._v(_vm._s(_vm._f("date")(_vm.transaction.bankBookingDate)))])]),_c('div',{staticClass:"lg:tw-col-start-2 tw-flex tw-flex-row"},[_c('div',{staticClass:"tw-w-1/2 lg:tw-w-1/3"},[_vm._v("Zuweisbarer Betrag:")]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(_vm.transaction.unassignedamount)))])]),_c('div',{staticClass:"lg:tw-col-start-1 tw-flex tw-flex-row"},[_c('div',{staticClass:"tw-w-1/3"},[_vm._v("Name:")]),_c('div',[_vm._v(_vm._s(_vm.transaction.counterpartName))])]),_c('div',{staticClass:"lg:tw-col-start-2 tw-flex tw-flex-row"},[_c('div',{staticClass:"tw-w-1/2 lg:tw-w-1/3"},[_vm._v("Bereits zugewiesen:")]),_c('div',[_c('Amount',{attrs:{"value":_vm.transaction.amount - _vm.transaction.unassignedamount}})],1)])])]),(_vm.transaction.details && !_vm.withoutProposals)?_c('div',{staticClass:"proposalwrapper"},[_c('ApolloQuery',{attrs:{"query":_vm.assignmentsAndProposalsQuery,"variables":{
        node: _vm.selectedNode,
        transactionid: _vm.transaction._id,
        minpoints: 6,
        maxnumber: 3,
        details: _vm.transaction.details,
      }},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading, error, data } }){return [(loading)?_c('div',{staticClass:"loading"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error"},[_vm._v("An error occurred")]):(data && data.assignmentsAndProposals.length > 0)?_c('div',[_c('Invoices',{attrs:{"invoices":data.assignmentsAndProposals,"transaction":_vm.transaction}})],1):(_vm.transaction.state === 'unassigned')?_c('div',[_c('v-alert',{attrs:{"border":"left","color":"#00A82D","icon":"mdi-information-outline","text":""}},[_vm._v(" Kein Vorschlag gefunden. "),_c('a',{staticClass:"textaction",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showDetailDialog', _vm.transaction)}}},[_vm._v(" Manuell zuweisen ")]),_vm._v(" oder als "),_c('a',{staticClass:"textaction",on:{"click":function($event){$event.stopPropagation();return _vm.handleMarkAsNotRelevant.apply(null, arguments)}}},[_vm._v(" Nicht relevant ")]),_vm._v(" markieren. ")])],1):_c('div',[_c('NoDataInfoText',{attrs:{"message":"Kein Vorschlag gefunden"}})],1)]}}],null,false,3527923180)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }