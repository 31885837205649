<template>
  <v-dialog v-model="value.show" :height="height" :width="width">
    <DialogContainer>
      <DialogHeader @close="cancel()">
        <template v-if="value.title">
          {{ value.title }}
        </template>
      </DialogHeader>
      <DialogBody class="tw-px-8 tw-pt-7 tw-pb-5">
        {{ value.text }}
      </DialogBody>
      <DialogFooter>
        <DialogActions>
          <SecondaryButton
            v-if="value.onDeny"
            tabindex="0"
            @click.prevent="cancel()">
            {{ value.denyText || 'Abbrechen' }}
          </SecondaryButton>
          <PrimaryButton autofocus tabindex="0" @click.prevent="confirm()">
            {{ value.confirmationText || 'OK' }}
          </PrimaryButton>
        </DialogActions>
      </DialogFooter>
    </DialogContainer>
  </v-dialog>
</template>

<script>
import PrimaryButton from '@/components/Buttons/PrimaryButton'
import SecondaryButton from '@/components/Buttons/SecondaryButton'
import DialogActions from '@/components/Dialog/DialogActions'
import DialogBody from '@/components/Dialog/DialogBody'
import DialogContainer from '@/components/Dialog/DialogContainer'
import DialogFooter from '@/components/Dialog/DialogFooter'
import DialogHeader from '@/components/Dialog/DialogHeader'

export default {
  components: {
    DialogActions,
    DialogHeader,
    DialogBody,
    DialogFooter,
    DialogContainer,
    SecondaryButton,
    PrimaryButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {
        return {
          show: false,
          text: '',
          icon: '',
          onConfirm: () => {},
          onDeny: () => {},
          confirmationText: 'OK',
          denyText: 'Abbrechen',
        }
      },
    },
    width: {
      type: String,
      default: '500px',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  methods: {
    confirm() {
      this.value.show = false
      this.value.onConfirm()
      this.$emit('confirmed')
    },
    cancel() {
      this.value.show = false
      this.value.onDeny()
      this.$emit('cancelled')
    },
  },
}
</script>
