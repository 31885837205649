<template>
  <div>
    <div class="tw-p-4 lg:tw-p-5 tw-bg-ci-gray-50">
      <div class="tw-grid lg:tw-grid-cols-2 lg:tw-gap-0.5">
        <div class="lg:tw-col-start-1 tw-flex tw-flex-row">
          <div class="tw-w-1/2 lg:tw-w-1/3">Betrag in EUR:</div>
          <div>{{ transaction.amount | currency }}</div>
        </div>
        <div class="lg:tw-col-start-2 tw-flex tw-flex-col lg:tw-flex-row">
          <div class="lg:tw-w-1/3">Verwendungszweck:</div>
          <div>
            {{
              transaction.details
                ? transaction.details.purpose
                : '(nicht sichtbar)'
            }}
          </div>
        </div>
        <div class="lg:tw-col-start-1 tw-flex tw-flex-row">
          <div class="tw-w-1/2 lg:tw-w-1/3">Buchungstag:</div>
          <div>{{ transaction.bankBookingDate | date }}</div>
        </div>
        <div class="lg:tw-col-start-2 tw-flex tw-flex-row">
          <div class="tw-w-1/2 lg:tw-w-1/3">Zuweisbarer Betrag:</div>
          <div>{{ transaction.unassignedamount | currency }}</div>
        </div>
        <div class="lg:tw-col-start-1 tw-flex tw-flex-row">
          <div class="tw-w-1/3">Name:</div>
          <div>{{ transaction.counterpartName }}</div>
        </div>
        <div class="lg:tw-col-start-2 tw-flex tw-flex-row">
          <div class="tw-w-1/2 lg:tw-w-1/3">Bereits zugewiesen:</div>
          <div>
            <Amount
              :value="transaction.amount - transaction.unassignedamount" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="transaction.details && !withoutProposals"
      class="proposalwrapper">
      <ApolloQuery
        :query="assignmentsAndProposalsQuery"
        :variables="{
          node: selectedNode,
          transactionid: transaction._id,
          minpoints: 6,
          maxnumber: 3,
          details: transaction.details,
        }">
        <template v-slot="{ result: { loading, error, data } }">
          <div v-if="loading" class="loading">Loading...</div>
          <div v-else-if="error" class="error">An error occurred</div>
          <div v-else-if="data && data.assignmentsAndProposals.length > 0">
            <Invoices
              :invoices="data.assignmentsAndProposals"
              :transaction="transaction" />
          </div>
          <div v-else-if="transaction.state === 'unassigned'">
            <v-alert
              border="left"
              color="#00A82D"
              icon="mdi-information-outline"
              text>
              Kein Vorschlag gefunden.
              <a
                class="textaction"
                @click.stop="$emit('showDetailDialog', transaction)">
                Manuell zuweisen
              </a>
              oder als
              <a class="textaction" @click.stop="handleMarkAsNotRelevant">
                Nicht relevant
              </a>
              markieren.
            </v-alert>
          </div>
          <div v-else>
            <NoDataInfoText message="Kein Vorschlag gefunden"></NoDataInfoText>
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { mapGetters } from 'vuex'
import ASSIGNMENTS_AND_PROPOSALS_QUERY from '../../gql/assignments_and_proposals.graphql'
import NoDataInfoText from '../helper/NoDataInfoText'
import Invoices from './Invoices'

export default {
  props: {
    transaction: Object,
    withoutProposals: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NoDataInfoText,
    Invoices,
  },
  computed: {
    ...mapGetters(['selectedNode']),
  },
  data() {
    return {
      assignmentsAndProposalsQuery: ASSIGNMENTS_AND_PROPOSALS_QUERY,
    }
  },
  methods: {
    handleMarkAsNotRelevant: function () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($transactionid: String!) {
            markTransactionAsNotRelevant(transactionid: $transactionid) {
              _id
              state
            }
          }
        `,
        variables: {
          transactionid: this.transaction._id,
        },
      })
    },
    handleMarkAsRelevant: function () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($transactionid: String!) {
            markTransactionAsRelevant(transactionid: $transactionid) {
              _id
              state
            }
          }
        `,
        variables: {
          transactionid: this.transaction._id,
        },
      })
    },
  },
}
</script>

<style scoped>
.proposalwrapper {
  width: 100%;
}

.textaction {
  color: #00a82d;
  text-decoration: underline;
}
</style>
